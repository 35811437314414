import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Sitemap = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var insurancePlanLinks = [];
  var bwImage = Images[0];
  var clrImage = Images[1];
  var planGroups = data.allStrapiInsuranceplans.group.map(document => {
    document.edges.map(planGroup => {
      planGroups = planGroup.node.category;
      insurancePlanLinks.push(`/${planGroup.node.urlPath}`);
    });
    return planGroups;
  });

  var leftcontent = planGroups.map(category => {
    var categoryName = category.charAt(0).toUpperCase() + category.slice(1);
    var title = ``;
    if (categoryName.indexOf(`Accident`) === 0) title = `Accident Care`;
    else if (categoryName.indexOf(`Combi`) === 0)
      title = categoryName + ` Products`;
    else if (categoryName.indexOf(`Global`) === -1)
      title = categoryName + ` Insurance`;

    var product = (
      <GridContainer key={category}>
        <GridItem>
          <h4 className={classes.cardTitle}>{title}</h4>
          <ul key={category}>
            {data.allStrapiInsuranceplans.group.map(document => {
              var links = document.edges.map(d => {
                // return (data.allSitePage.edges.map(link => {
                // var plan_link = d.node.
                if (category == d.node.category && d.node.urlPath !== null)
                  return (
                    <li key={d.node.strapiId}>
                      <Link to={`/${d.node.urlPath}`} target="_blank">
                        {d.node.name}
                      </Link>
                    </li>
                  );
              });
              return links;
            })}
          </ul>
        </GridItem>
      </GridContainer>
    );
    return product;
  });

  var middlecontent = data.allSitePage.edges.map(link => {
    if (link.node.path.startsWith(`/claims`)) {
      return [
        <li key="claims">
          <Link to="claims" target="_blank">
            Claim Process
          </Link>
        </li>,
        <li key="claims1">
          <Link to="claims#1" target="_blank">
            Claims Intimation
          </Link>
        </li>,
        <li key="claims2">
          <Link to="claims#2" target="_blank">
            Claims Helpdesk
          </Link>
        </li>,
        <li key="claims3">
          <Link to="claims#3" target="_blank">
            Claims Status
          </Link>
        </li>,
        <li key="claims4">
        <Link to="claims43" target="_blank">
          Claims Upload
        </Link>
      </li>
      ];
    }
  });

  const getTitle = title => {
    switch (title) {
      case `Agent List`:
        return `List of Agents`;
      case `List Products`:
        return `List of Products`;
      case `Claim Proposal`:
        return `Unclaimed Amount`;
      case `Star Glossary`:
        return `Glossary`;
      case `Download`:
        return `Downloads`;
      default:
        return title;
    }
  };

  var rightcontent = data.allSitePage.edges.map(link => {
    if (
      link.node.path.indexOf(`Insuranceplans`) == -1 &&
      link.node.path.indexOf(`plans`) == -1 &&
      !link.node.path.startsWith(`/404`) &&
      !(link.node.path.startsWith(`/claim`) && !link.node.path.includes(`-`)) &&
      !link.node.path.startsWith(`/about`) &&
      !link.node.path.startsWith(`/media-center`) &&
      !link.node.path.startsWith(`/locate`) &&
      !link.node.path.startsWith(`/partner`) &&
      !link.node.path.startsWith(`/network`) &&
      !link.node.path.startsWith(`/portability`) &&
      !link.node.path.startsWith(`/financeByQuarter/Q`) &&
      !link.node.path.startsWith(`/conversational`) &&
      !link.node.path.startsWith(`/page-2`) &&
      !link.node.path.startsWith(`/dev-404-page`) &&
      !link.node.path.startsWith(`/insuretech`) &&
      !link.node.path.startsWith(`/ServiceParameter`) &&
      !link.node.path.includes(`/lp`) &&
      !link.node.path.includes(`/Thankyou`) &&
      !link.node.path.includes(`/thank-you`) &&
      !link.node.path.includes(`2018`) &&
      !link.node.path.includes(`/MaintanencePage`) &&
      !(link.node.path == `/sitemap`) &&
      !(
        link.node.component && link.node.component.includes(`financeByQuarter`)
      ) &&
      !(link.node.path == `/null`) &&
      insurancePlanLinks.indexOf(link.node.path) == -1
    )
      if (link.node.path.split(`/`).pop() != ``) {
        var title = link.node.path.split(`/`).pop();
        title = title.split(`-`);
        title = title.map(ele => {
          switch (ele.toLowerCase()) {
            case `dnd`:
            case `faq`:
            case `irdai`:
              return ele.toUpperCase();
            default:
              return ele.charAt(0).toUpperCase() + ele.slice(1);
          }
        });
        title = title.join(` `);
        // console.log(link.node, title);
        if(title == 'Safebuying'){
          title = 'Safe Buying'
        }
        else if(title == 'PortabilityPlans'){
          title = 'Portability Plans'
        }
        else if(title == 'Static Privacy Policy'){
          title = 'Privacy Policy'
          link.node.path = '/disclaimer#privacy_policy'
        }
        return (
          <li key={link.node.path}>
            <Link to={link.node.path} target="_blank">
              {getTitle(title)}
            </Link>
          </li>
        );
      }
  });

  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>Sitemap | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link rel="canonical" href="https://www.starhealth.in/sitemap" />
          <meta name="title" content="Sitemap | StarHealth.in" />
          <meta name="twitter:title" content="Sitemap | StarHealth.in" />
          <meta name="description" content="Sitemap for Bots" />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India"
          />
          <meta property="og:title" content="Sitemap | StarHealth.in" />
          <meta property="og:description" content="Sitemap for Bots" />
          <meta property="og:url" content="https://www.starhealth.in/sitemap"/>
          <meta name="Sitemap | StarHealth.in" />
          <meta name="twitter:description" content="Sitemap for Bots" />
          <meta property="twitter:url" content="https://www.starhealth.in/sitemap" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: `100px` }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            {/* <h2 className={classNames(classes.title, classes.textCenter)}>Sitemap</h2> */}
            <GridContainer style={{ marginLeft: `5%`, paddingTop: `20px` }}>
              <GridItem xs={12} md={4} sm={12} style={{ float: `left` }}>
                {leftcontent}
              </GridItem>
              <GridItem xs={12} md={4} sm={12}>
                <h4 className={classes.cardTitle}>About Us</h4>
                <ul>
                  <li key="aboutUs">
                    <Link to="aboutUs" target="_blank">
                      About Us
                    </Link>
                  </li>
                  <li key="aboutUs1">
                    <Link to="aboutUs#1" target="_blank">
                      Why Choose Us
                    </Link>
                  </li>
                  <li key="aboutUs2">
                    <Link to="aboutUs#2" target="_blank">
                      Board Of Directors
                    </Link>
                  </li>
                  {/* <li key="aboutUs3">
                    <Link to="aboutUs#3" target="_blank">
                      Top Management Team
                    </Link>
                  </li> */}
                  <li key="aboutUs4">
                    <Link to="aboutUs#3" target="_blank">
                      Key Management Persons
                    </Link>
                  </li>
                  <li key="aboutUs5">
                    <Link to="aboutUs#4" target="_blank">
                      Citizens Charter
                    </Link>
                  </li>
                </ul>
                <h4 className={classes.cardTitle}>Claims</h4>
                <ul>{middlecontent}</ul>
                <h4 className={classes.cardTitle}>Home</h4>
                <ul>
                  <li key="plans">
                    <Link to="health-insurance-plans" target="_blank">
                      Plans
                    </Link>
                  </li>
                  <li key="renewals">
                    <a
                      href="/customerportal/auto-debit"
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Auto Debit
                    </a>
                  </li>
                  <li key="renewals">
                    <a
                      href="/customerportal/instant-renewal"
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Renewals
                    </a>
                  </li>
                  <li key="renewals">
                    <a
                      href="/customerportal/"
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Get Your E-policy
                    </a>
                  </li>
                  <li key="locate-us">
                    <Link to="locate-us" target="_blank">
                      Locate Us
                    </Link>
                  </li>
                  <li key="network-hospitals">
                    <Link to="network-hospitals" target="_blank">
                      Network Hospitals
                    </Link>
                  </li>
                  <li key="partners-corner">
                    <Link to="partners-corner" target="_blank">
                      Partners Corner
                    </Link>
                  </li>
                  <li key="portability">
                    <Link to="portability" target="_blank">
                      Portability
                    </Link>
                  </li>
                </ul>
                <h4 className={classes.cardTitle}>Media Center</h4>
                <ul>
                  <li key="media-center">
                    <Link to="media-center" target="_blank">
                      News
                    </Link>
                  </li>
                  <li key="media-center1">
                    <Link to="media-center/#1" target="_blank">
                      Awards
                    </Link>
                  </li>
                  <li key="media-center2">
                    <Link to="media-center/#2" target="_blank">
                      Media Campaigns
                    </Link>
                  </li>
                  <li key="media-center3">
                    <Link to="media-center/#3" target="_blank">
                      Public Disclosure
                    </Link>
                  </li>
                  <li key="media-center2">
                    <Link to="media-center/#4" target="_blank">
                    Health Magazine
                    </Link>
                  </li>
                  {/* <li key="media-center2">
                    <Link to="media-center/#5" target="_blank">
                    Honeybees E-Newsletter
                    </Link>
                  </li> */}
                </ul>
              </GridItem>
              <GridItem xs={12} md={4} sm={12} style={{ float: `right` }}>
                <h4 className={classes.cardTitle}>Others</h4>
                <ul>{rightcontent}</ul>
                <h4 className={classes.cardTitle}>
                  Star Health on Social Media
                </h4>
                <ul>
                  <li key="social">
                    <a
                      href="https://www.facebook.com/StarHealth?fref=ts"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Facebook
                    </a>
                  </li>
                  <li key="social1">
                    <a
                      href="https://twitter.com/starhealthins"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Twitter
                    </a>
                  </li>
                  <li key="social3">
                    <a
                      href="https://www.linkedin.com/company/star-health-and-allied-insurance-co--ltd"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li key="social2">
                    <a
                      href="https://www.youtube.com/user/StarhealthInsurance"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      YouTube
                    </a>
                  </li>
                  <li key="social4">
                    <a
                      href="https://www.instagram.com/starhealth.insurance/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Sitemap.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export default withStyles(aboutUsStyle)(Sitemap);

export const sitemapQuery = graphql`
  query Sitemap {
    allSitePage(sort: { fields: path, order: ASC }) {
      edges {
        node {
          path
          component
        }
      }
    }
    allStrapiInsuranceplans(
      filter: {
        isWithdrawn: { eq: false }
        category: { ne: "global" }
        isVisible: { eq: true }
      }
    ) {
      group(field: category) {
        edges {
          node {
            strapiId
            name
            category
            urlPath
          }
        }
      }
    }
    allStrapiImages(filter: { category: { eq: "sitemap" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
